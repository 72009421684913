import React, { useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import constants from '../utils/constants';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import history from "../utils/history";
import CardContainer from '../components/CardContainer';
import { getImage, getNextScreen, setSkip } from '../utils/common';
import {postFindImpression, updateProfileStep} from "../api/service";
import LoadingSpinner from "../components/LoadingSpinner";
import SkipToJob from "../components/SkipToJob";

const ViewQuote = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const [perks, setPerks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchImpressionCompeted, setFetchImpressionCompeted] = useState(false);
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_OFFER_SCREEN)}`);
    setSkip(nextScreen);
    updateProfileStep(constants.AUTO_OFFER_SCREEN).then(()=> {
      console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_OFFER_SCREEN);
    });
    setIsLoading(true);
    setFetchImpressionCompeted(false);
    postFindImpression()
      .then((response) => {
        let perksArray = []
        if (response.data) {
          const data = response.data.data;
          data.forEach(job => {
            perksArray.push(job);
          });
        }
        setPerks(perksArray);
        setIsLoading(false);
        setFetchImpressionCompeted(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchImpressionCompeted(true);
        console.error(error);
      });
  }, [userId, nextScreen, hotCocoaConfig]);

  const handleOnClickViewQuote = () => {
    if (hotCocoaConfig) {
      const jsonCocoaConfig = JSON?.parse(hotCocoaConfig)?.hococoaConfig?.autoinsoffer?.viewquotenow?.toString();
      if (jsonCocoaConfig?.includes('http')) {
        window.location.assign(perks[0]?.clickUrl)
      } else {
        history.push(jsonCocoaConfig);
      }
    } else {
      history.push(nextScreen);
    }
  }

  const renderJobs = () => {
    if (isLoading) {
      return <LoadingSpinner showLoader={isLoading}/>;
    }
    return (
      perks.map((perk) => {
        return (
          <div key={perk.id}>
            <Card className="Job-card mt-3">
              <Card.Body>
                <Row>
                  <Col md={9}>
                    <Card.Link href={perk.clickUrl} target="_blank" className="mt-1 Job-title">{perk.title}</Card.Link>
                    <Card.Subtitle className="Job-subtitle mt-2 mb-2">{perk.displayName}</Card.Subtitle>
                  </Col>
                  <Col className="my-auto">
                    <Card.Img src={perk.logoUrl === null || perk.logoUrl === 'null' ?
                      getImage('/icons/jobble-job-logo.png') : perk.logoUrl} className="Job-arrow Job-image" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        );
      })
    );
  };

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={90}>
        <Card.Title className="Quote-Page-Title">Congratulations!</Card.Title>
        <Card.Title>We found an exclusive auto insurance offer for you!
          {/*View your quote now, or check your inbox later to start saving.*/}
        </Card.Title>
        {renderJobs()}
        <Button className={`Form-continue mt-3 ${perks?.length === 0 ? 'd-none' : ''}`} type="submit"
                onClick={() => handleOnClickViewQuote()}>View Quote Now</Button>
        <Card.Title className={`${fetchImpressionCompeted && perks?.length === 0 ? '' : 'd-none'} App-question mt-4 mb-3 text-center`}>
          No Data Found
        </Card.Title>
        <SkipToJob/>
    </CardContainer>
  );
};

export default ViewQuote;