import React, { useEffect } from 'react';

import CardContainer from '../components/CardContainer';
import LoadingSpinner from "../components/LoadingSpinner";
import { getHotCocoaScreens } from "../api/service";
import constants from "../utils/constants";
import history from '../utils/history';
import { getFirstScreen } from "../utils/common";

const LandingPage = () => {
  const publisherName = localStorage.getItem(constants.J_PUB) || '';
  useEffect(() => {
    getHotCocoaScreens(publisherName).then((response) => {
      if (response.data?.screens) {
        localStorage.setItem(constants.PUBLISHER_SCREENS, response?.data?.screens);
        localStorage.setItem(constants.J_PUB, response?.data?.publisher_name);
        localStorage.setItem(constants.PERK_PUBLISHER_CAMPAIGN_ID, response?.data?.perks_publisher_campaign_id);
        localStorage.setItem(constants.HOT_COCOA_CONFIG, response?.data?.hotcocoa_config);
        history.push(`/${getFirstScreen()}`)
      } else {
        localStorage.setItem(constants.PUBLISHER_SCREENS, constants.DEFAULT_SCREEN);
        history.push(`/${getFirstScreen()}`)
      }
    }).catch((error) => {
      localStorage.setItem(constants.PUBLISHER_SCREENS, constants.DEFAULT_SCREEN);
      history.push(`/${getFirstScreen()}`)
    });
  }, [publisherName]);

  return (
  <CardContainer title="DISCOVER THE RIGHT FIT">
    <LoadingSpinner showLoader={true}/>
  </CardContainer>
  );
};

export default LandingPage;