import React, { useRef, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { getNextScreen, setSkip } from '../utils/common';
import Button from "react-bootstrap/Button";
import carData from './../car_data.json';
import SkipToJob from "../components/SkipToJob";

const CarDetails = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const form = useRef(null);
  const todayDate = new Date()
  const [carModel, setCarModel] = useState([]);
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_CAR_DETAILS_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.AUTO_CAR_DETAILS_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_CAR_DETAILS_SCREEN);
    // });
    window.$('#provider input').attr('required', 'required');
  }, [userId, nextScreen, hotCocoaConfig]);

  const renderYears = () => {
    let years = []
    for (let i = todayDate.getFullYear(); i >= 1981; i--) {
      years.push(i)
    }

    return (
      years.map((year) => {
        return (
          <option key={`option`+year} value={year}>{year}</option>
        );
      })
    );
  }

  const renderInsuranceProvider = () => {
    return (
      constants.AUTO_INSURANCE_PROVIDER.map((provider) => {
        return (
          <option key={`auto-option-`+provider} value={provider}>{provider}</option>
        );
      })
    );
  }

  const renderCarMake = () => {
    return (
      Object.keys(carData).sort().map((maker) => {
        return (
          <option key={`option-maker-`+maker} value={maker}>{maker}</option>
        );
      })
    );
  }

  const handleOnChangeCarMake = (event) => {
    if (event.target.value) {
      setCarModel(carData[event.target.value])
    } else {
      setCarModel([])
    }
  }

  const renderCarModel = () => {
    return (
      carModel.sort().map((model) => {
        return (
          <option key={`option-model-`+model} value={model}>{model}</option>
        );
      })
    );
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.AUTO_CAR_DETAILS_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      insurance: {
        insurance_provider: form.current.insuranceProvider.value,
        car_year: form.current.car_year.value,
        car_make: form.current.car_make.value,
        car_model: form.current.car_model.value
      }
    }
    // console.log('CarDetails > ', data)
    updateProfile(data);
    history.push(nextScreen);
  };

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={50}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="Auto-Insu-title">{constants.AUTO_APP_TITLE_TEXT}</Card.Title>

        <Card.Title className="App-question mt-4 mb-3">What is the name of your current insurance provider?</Card.Title>
        <select id="insuranceProvider" name="insuranceProvider" className="Form-combo mt-2 mb-2" required>
          <option value="">Insurance Provider</option>
          {renderInsuranceProvider()}
        </select>

        <Form.Text className="Form-label">Tell us about your car.</Form.Text>
        <select id="car_year" className="Form-combo mt-2 mb-3" required>
          <option value="">Year</option>
          {renderYears()}
        </select>
        <select id="car_make" className="Form-combo mt-2 mb-3" required onChange={handleOnChangeCarMake}>
          <option value="">Make</option>
          {renderCarMake()}
        </select>
        <select id="car_model" className="Form-combo mt-2 mb-3" required>
          <option value="">Model</option>
          {renderCarModel()}
        </select>

        <Button className="Form-continue mt-4" type="submit">Submit</Button>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default CarDetails;