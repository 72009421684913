
import React, { useEffect, useState } from 'react';
import constants from "../utils/constants";

const SkipToJob = () => {
  const cwJobUrl = localStorage.getItem(constants.CW_JOB_URL);
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);
  const [hideSkipToJob, setHideSkipToJob] = useState('');

  useEffect(() => {
    if (hotCocoaConfig) {
      const jsonHotCocoaConfig = JSON?.parse(hotCocoaConfig)
      if (jsonHotCocoaConfig?.hococoaConfig?.global?.skiptojob?.toString() === 'false') {
        setHideSkipToJob('d-none');
      }
    }
  }, [hotCocoaConfig]);

  const renderSkipToJobUrl = () => {
    let skipToJobUrl = `/${constants.JOB_LIST_SCREEN}`;
    if (cwJobUrl && cwJobUrl !== '' && userId && userId !== '') {
      skipToJobUrl = `${cwJobUrl}&userId=${userId}`;
    }
    return skipToJobUrl;
  }

  return(
    <>
      <div className={`Skip-To-Jobs ${hideSkipToJob}`}>
        <a href={renderSkipToJobUrl()} rel="noopener noreferrer" id="skip-link">Skip to job</a>
      </div>
    </>
  );
};

export default SkipToJob;