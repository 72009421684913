import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { validPhoneRegex, formatPhone, formatPhoneNumbers } from '../utils/validate';
import { getNextScreen, setSkip } from '../utils/common';
import 'react-datepicker/dist/react-datepicker.css';
import SkipToJob from "../components/SkipToJob";

const Mobile = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.EDU_STATUS_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const defaultDate = new Date();
  const [birthDay, setBirthDay] = useState(localStorage.getItem(constants.BIRTH_DAY) || '');
  const zipCode  = localStorage.getItem(constants.USER_ZIP_CODE) || 'you';
  const JOBS_TITLE = `${localStorage.getItem(constants.JOB_CATEGORY) || ''} Jobs Near ${zipCode}`;
  const mobile = localStorage.getItem(constants.USER_PHONE) || '';
  const [birthYear, setBirthYear] = useState(localStorage.getItem(constants.BIRTH_YEAR) || '');
  const [birthMonth, setBirthMonth] = useState(localStorage.getItem(constants.BIRTH_MONTH) || '');
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.USER_MOBILE_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.USER_MOBILE_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.USER_MOBILE_SCREEN);
    // });
    if (mobile) {
      form.current.mobileNumber.value = `(${mobile.substring(0, 3)}) ${mobile.substring(3, 6)} - ${mobile.substring(6, 10)}`
    }
  }, [nextScreen, hotCocoaConfig, mobile]);

  const onSubmit = (event) => {
    event.preventDefault();
    const mobile = formatPhone(form.current.mobileNumber.value);
    if (!mobile || !validPhoneRegex.test(mobile)) {
      setError('Please, provide a valid phone. Must be 10 digits');
      return;
    }
    setError('');
    // Set BirthDate in yyyy-MM-dd format
    const birthDate = `${form.current.birthYear.value}-${form.current.birthMonth.value}-${birthDay}`
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.USER_MOBILE_SCREEN,
      birth_date: birthDate,
      mobile: formatPhoneNumbers(mobile),
      is_mobile_alert_subscribed: form.current.jobAlerts.checked,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA
    }
    updateProfile(data);
    history.push(nextScreen);
  };

  const onKeyUp = (event) => {
    event.preventDefault();
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 8 || charCode === 46) {
      return true;
    } else {
      event.target.value = formatPhone(event.target.value);
    }
  };

  const handleBirthDayChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      if (Number(event.target.value) <= 31) {
        setBirthDay(event.target.value);
      }
    }
  }

  const handleBirthMonth = (event) => {
    setBirthMonth(event.target.value)
  }

  const handleBirthYear = (event) => {
    setBirthYear(event.target.value)
  }

  const renderYears = () => {
    let years = []
    for (let i = defaultDate.getFullYear()-18; i >= 1945; i--) {
      years.push(i)
    }

    return (
      years.map((year) => {
        return (
          <option key={`option`+year} value={year}>{year}</option>
        );
      })
    );
  }

  return (
    <CardContainer title={JOBS_TITLE}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="App-title mt-4">Almost there!</Card.Title>
        <Card.Title className="App-question mb-3">Confirm your age and mobile number to continue</Card.Title>

        <Form.Group>
          <Form.Text className="Form-label">Date of birth</Form.Text>
          <div className="Form-row">
            <Form.Control id="birthMonth" className="Form-Date-box mt-2 mb-2" as="select" required value={birthMonth} onChange={handleBirthMonth}>
              <option value="">Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Form.Control>
            <input id="birthDay" type="text" className="Form-Date-box mt-2 mb-2" placeholder="DD" defaultValue={birthDay}
                   maxLength={2} onChange={handleBirthDayChange} required/>
            <Form.Control id="birthYear" className="Form-Date-box mt-2 mb-2" as="select" required value={birthYear} onChange={handleBirthYear}>
              <option value="">Year</option>
              {renderYears()}
            </Form.Control>
          </div>
        </Form.Group>
        
        <Form.Group>
          <Form.Text className="Form-label">Mobile number</Form.Text>
          <input id="mobileNumber" type="text" defaultValue={mobile} className="Form-box mt-2 mb-2" placeholder="Mobile number" onKeyUp={onKeyUp} required />
        </Form.Group>

        <div className="policy">
          <label className="mt-2 mb-2">
            By checking "I AGREE" and clicking "SUBMIT" I understand that I consent and request to be called or receive text messages (msg and data rates may apply) by some of our Marketing Partners, including MyDegree LLC., Results Generation LLC., Talent.com, Trending Jobs, Horizon2Media, and ADCALLSERVICES at the landline or mobile phone number I provided, even if my number is on the federal or state do not call list.
          </label>

          <label className="mt-2 mb-2">
            I acknowledge that the call of SMS message may be made using an autodialer and may include prerecorded messages. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered.
          </label>

          <label className="mt-2 mb-2">
          For SMS message campaigns: Text STOP to stop and HELP for help.
          Msg and data rates may apply. Periodic messages; max 30/month.
          </label>
        </div>
        <div className="agreement mt-3">
          <div className="checkbox-bg text-center custom-control custom-checkbox">
            <input type="checkbox" className="text-center custom-control-input mt-1 mb-2" id="jobAlerts" />
            <label className="custom-control-label small" htmlFor="jobAlerts">
              I Agree
            </label>
          </div>
        </div>
        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Button className="Form-continue mt-4" type="submit">Submit</Button>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default Mobile;