// CM URL and keys
const CM_URL = `${process.env.REACT_APP_CM_URL}`;
const CM_API_KEY = `${process.env.REACT_APP_CM_API_KEY}`;
const CM_SECRET_KEY = `${process.env.REACT_APP_CM_SECRET_KEY}`;
const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

// External Services URLs
const IP_API_URL = `https://pro.ip-api.com/json/?key=${process.env.REACT_APP_IP_API_KEY}`;

const TIMEOUT_MS = 5000;

module.exports = {
  CM_URL,
  CM_API_KEY,
  CM_SECRET_KEY,
  IP_API_URL,
  TIMEOUT_MS,
  GOOGLE_API_KEY
};