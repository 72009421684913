// eslint-disable-next-line
import React, { useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import constants from "../utils/constants";
import { updateProfile } from "../api/service";

const DoNotSellModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function onSubmit(value) {
    const email = localStorage.getItem(constants.USER_EMAIL)
    localStorage.setItem(constants.IS_MY_INFO_SELLABLE, value)
    if (email) {
      const data = {
        email: email,
        is_my_info_sellable: value
      }
      updateProfile(data)
    }
    handleClose();
  }

  return(
    <>
      <span className="CursorPointer text-decoration-underline" onClick={handleShow}>Do Not Sell My Info</span>.
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="Bold-Text">
              Are you sure? We use the information you provide to match you with offers from our partners.
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We do not sell your info without your approval. Our service is free to use. To offer our services, we get paid by partners when we make a referral. If we are unable to use your info when making a referral, we will not be able to properly match you. Click Never mind to continue your search for savings or "Do not sell my info" to discontinue your use (your information will not be sold).

          <Form.Group className="mb-2">
            <Button className="Form-continue mt-4" onClick={() =>onSubmit(true)}>
              Never mind, I want offers
            </Button>
          </Form.Group>
          <Form.Group>
            <Button className="DoNotSellPopUp" type="submit" onClick={() =>onSubmit(false)}>
              Do not sell my info <br/>
              <span className="Small-Text">I understand I won't get offers.</span>
            </Button>
          </Form.Group>

          <div className="Small-Text text-center">
            <label className="mt-2 mb-2">
              For more information, see our <a href="https://kb.jobble.com/privacy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
            </label>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DoNotSellModal;