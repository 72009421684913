import constants from './constants';

export const changeBackground = (event) => {
    const label = "label_" + event.target.id;
    const options = document.getElementsByName("options");
    for (const option of options) {
        const color = label ===  option.id ? constants.COLOR_LABEL_BG_CHECKED : constants.COLOR_LABEL_BG;
        option.style.setProperty('background-color', color, 'important');
    }
};

export const toCamelCase = (str) => {
    return str ? str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase() : '';
};

export const toTitleCase = (str) => {
  const words = str.split(" ");
  return words.map((word) => {
    return word ? word[0]?.toUpperCase() + word?.substring(1) : '';
  }).join(" ");
};

export const setSkip = (nextScreen) => {
  const link = document.getElementById("skip-link");
  link.href = nextScreen;
  const skip = document.getElementById("skip-div");
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);
  if (nextScreen === '/' || (hotCocoaConfig && JSON?.parse(hotCocoaConfig)?.hococoaConfig?.global?.skip?.toString() === 'false')) {
      skip.className = 'd-none';
  } else {
      skip.className = 'Skip';
  }
};

export const setGender = (gender) => {
  switch (gender) {
    case 'm':
      localStorage.setItem(constants.USER_GENDER, 'male');
      break
    case 'f':
      localStorage.setItem(constants.USER_GENDER, 'female');
      break
    case 'o':
      localStorage.setItem(constants.USER_GENDER, 'other');
      break
    default :
      localStorage.setItem(constants.USER_GENDER, gender?.toString()?.toLowerCase());
      break
  }
};

export const getImage = (assetPath) => {
  return process.env.PUBLIC_URL + assetPath;
}

export const getNextScreen = (currentScreen) => {
  let pubConfigScreens = localStorage.getItem(constants.PUBLISHER_SCREENS);
  if(!pubConfigScreens) {
    pubConfigScreens = constants.DEFAULT_SCREEN;
  }
  let screenArray = pubConfigScreens?.split(','); //.map(Number);
  let nextScreen = screenArray[screenArray.indexOf(currentScreen) + 1];
  if (nextScreen) {
    return nextScreen;
  } else {
    // If nextScreen not available then returning screen number of single job page
    return constants.HOT_JOB_SCREEN;
  }
}

export const getFirstScreen = () => {
  let pubConfigScreens = localStorage.getItem(constants.PUBLISHER_SCREENS);
  // console.log("pubConfigScreens > ", pubConfigScreens)
  if(!pubConfigScreens) {
    pubConfigScreens = constants.DEFAULT_SCREEN;
  }
  let screenArray = pubConfigScreens?.split(','); //.map(Number);
  // console.log("screenArray > ", screenArray)
  let nextScreen = screenArray[0];
  if (nextScreen) {
    return nextScreen;
  } else {
    return constants.JOB_TYPE_SCREEN;
  }
}

export const pad = (n) => {
  return (n.length < 2) ? ("0" + n) : n;
}