import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import constants from "../utils/constants";

const CardContainer = (props) => {
  const cwJobUrl = localStorage.getItem(constants.CW_JOB_URL);
  const userId = localStorage.getItem(constants.USER_ID);

  useEffect(() => {
    const openCMJob = () => {
      let jobUrl = cwJobUrl
      if (jobUrl) {
        if (userId && userId !== '') {
          jobUrl += `&userId=${userId}`
        }
        window.location.assign(jobUrl)
      }
    }

    window.onblur = openCMJob;
  }, [cwJobUrl, userId])

  return (
    <div className="mt-3 Separator">
      <Container fluid>
        <Row noGutters="true">
          <Col>
            <Card className="App-card mb-2">
              <Card.Title id="title" className=" text-center App-card-header mb-0">{props.title}</Card.Title>
              <ProgressBar className={`App-progressbar ${props.progress ? '' : 'd-none'}`} now={props.progress} />
              <Card.Body className="App-card-body">
                {props.children}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CardContainer;