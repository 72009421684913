import axios from 'axios';

import config from './config';

const instance = axios.create({
  baseURL: config.CM_URL,
  headers: {
    'cm_api_key': `${process.env.REACT_APP_CM_API_KEY}`,
    'cm_secret_key': `${process.env.REACT_APP_CM_SECRET_KEY}`
  }
});

const cwInstance = axios.create({
  baseURL: config.CM_URL,
  headers: {
    'cm_api_key': `${process.env.REACT_APP_CM_APPCAST_API_KEY}`,
    'cm_secret_key': `${process.env.REACT_APP_CM_APPCAST_SECRET_KEY}`
  }
});

const apiMethods = {
  getData: (url) =>
    instance({
      method: 'GET',
      url
    }),
  postData: (url, data) =>
    instance({
      method: 'POST',
      url,
      data
    }),
  putData: (url, data) =>
    instance({
      method: 'PUT',
      url,
      data
    }),
  getCwData: (url) =>
    cwInstance({
      method: 'GET',
      url
    }),
};

export default apiMethods;