// eslint-disable-next-line
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
// eslint-disable-next-line
const validPhoneRegex = RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);

const validZipcodeRegex = RegExp(/^\d{5}(?:[-\s]\d{4})?$/);

const validDateRegex = RegExp(/^\d{1,2}-\d{1,2}-\d{4}$/);

const formatPhoneNumbers = (str) => {
  if (str.length === 0) return str;
  str = str.replace(/\D/g, '');
  return '' + str;
};

const formatPhone = (str) => {
  if (str.length === 0) return str;
  let phone = '(';
  str = str.replace(/\D/g, '').substring(0, 10);
  if (str.length < 3) {
    phone += str;
  } else if (str.length < 6) {
    phone += str.substring(0, 3) + ') ';
    if (str.length > 3) phone += str.substring(3, str.length);
  } else {
    phone += str.substring(0, 3) + ') ' + str.substring(3, 6) + '-' + str.substring(6, 10);
  }
  return phone;
};

module.exports = {
  validEmailRegex,
  validPhoneRegex,
  validZipcodeRegex,
  validDateRegex,
  formatPhone,
  formatPhoneNumbers
};