import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import CardContainer from '../components/CardContainer';
import { getJobs, updateProfileStep } from '../api/service';
import constants from '../utils/constants';
import { getImage, setSkip } from '../utils/common';

const JobsThree = () => {
  const nextScreen = '/';
  const form = useRef(null);
  const [jobs, setJobs] = useState([]);
  const [singleJobUrl, setSingleJobUrl] = useState('');
  const [cmJobBoardUrl, setCmJobBoardUrl] = useState('https://jobs.jobble.com/');
  const [hideSkipToJob, setHideSkipToJob] = useState('d-none');
  const cwJobUrl = localStorage.getItem(constants.CW_JOB_URL);
  const userId = localStorage.getItem(constants.USER_ID);

  useEffect(() => {
    setSkip(nextScreen);
    if(userId){
      updateProfileStep(constants.JOB_LIST_SCREEN).then(()=> {
        // console.log('Userprofile Updated Successfully for screen : ', constants.JOB_LIST_SCREEN);
      });
    }

    let mounted = true;
    const fetchData = () => {
      getJobs(3)
        .then((response) => {
          const jobsArray = [];
          if (response.data) {
            const data = response.data.data;
            data.forEach(job => {
              jobsArray.push(job);
            });
          }
          if (mounted) {
            setJobs(jobsArray);
            if (jobsArray.length > 0) {
              setSingleJobUrl(jobsArray[0].url);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchData();

    //Setting Category and City to pass it along with redirection url job jobble job board.
    let category = localStorage.getItem(constants.JOB_CATEGORY);
    // let city = localStorage.getItem(constants.USER_CITY);
    // let state = localStorage.getItem(constants.USER_STATE_CODE);
    // let queryParams = 'CATEGORY-jobs-in-STATE-CITY'
    // if (category) {
    //   queryParams = `${category}-jobs`;
    //   if (state) {
    //     queryParams = queryParams + `-in-${state}`;
    //     if (city) {
    //       queryParams = queryParams + `-${city}`
    //     }
    //   } else {
    //     if (city) {
    //       queryParams = queryParams + `-in-${city}`
    //     }
    //   }
    // } else {
    //   if (state) {
    //     queryParams = `jobs-in-${state}`;
    //     if (city) {
    //       queryParams = queryParams + `-${city}`
    //     }
    //   } else {
    //     if (city) {
    //       queryParams = `jobs-in-${city}`
    //     }
    //   }
    // }

    let queryParams = ''
    if (category) {
      queryParams = `?category=${category}`
    }

    setCmJobBoardUrl(`https://jobs.jobble.com/${queryParams}`);

    if (cwJobUrl && cwJobUrl !== '' && userId && userId !== '') {
      setHideSkipToJob('');
    }
    return () => mounted = false;
  }, [cwJobUrl, userId]);

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    const newWindow = window.open(`${singleJobUrl}${userId ? '&userId=' + userId : ''}`, '_blank')
    if (newWindow) newWindow.opener = null
    window.location.assign(cmJobBoardUrl)
  }

  const renderJobs = () => {

    return (
      jobs.map((job) => {
        return (
          <div key={job.job_id}>
            <Card className="Job-card mt-3">
                <Card.Body>
                  <Row>
                    <Col md={9}>
                      <Card.Link href={`${job.url}${ userId ? '&userId=' + userId : ''}`} target="_blank" className="mt-1 Job-title">{job.title}</Card.Link>
                      <Card.Subtitle className="Job-subtitle mt-2 mb-2">{job.company}</Card.Subtitle>
                      <Card.Subtitle className="Job-subtitle">{job.city}, {job.state}</Card.Subtitle>
                    </Col>
                    <Col className="my-auto">
                      <Card.Img src={job.logo_url === null || job.logo_url === 'null' ? getImage('/icons/jobble-job-logo.png') : job.logo_url} className="Job-arrow Job-image" />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          </div>
        );
      })
    );
  };

  return (
    <CardContainer title={constants.PROFILE_COMPLETE} progress={100}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="Quote-Page-Title">Trending Jobs Near You</Card.Title>
        <Card.Title>While we finish curating your jobs, here are some popular opportunities that match your profile.</Card.Title>
        {renderJobs()}
        <Button className={`Form-continue mt-3 ${singleJobUrl ? '' : 'd-none'}`} type="submit" onClick={handleClick}>I'm feeling lucky!</Button>
        <div className="Skip-button mt-3">
          <a href={cmJobBoardUrl}>View All Jobs</a>
        </div>
      </Form>
      <div className={`Skip-To-Jobs ${hideSkipToJob}`}>
        <a href={`${cwJobUrl}${userId ? '&userId=' + userId : ''}`} rel="noopener noreferrer" id="skip-link">Skip to job</a>
      </div>
    </CardContainer>
  );
};

export default JobsThree;