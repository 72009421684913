import React, { useRef, useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import constants from '../utils/constants';
import { getNextScreen, setSkip } from '../utils/common';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { validZipcodeRegex } from "../utils/validate";
import history from '../utils/history';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import SkipToJob from "../components/SkipToJob";
import { GOOGLE_API_KEY } from "../api/config";
import { geocodeByAddress } from 'react-google-places-autocomplete';

const CompleteAddress = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const userId = localStorage.getItem(constants.USER_ID);
  const userZip = localStorage.getItem(constants.USER_ZIP_CODE) || localStorage.getItem(constants.IP_ZIP_CODE)
  const [completeAddress, setCompleteAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_ADDRESS_SCREEN)}`);
    setSkip(nextScreen);
    setZip(userZip)
    // updateProfileStep(constants.AUTO_ADDRESS_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_ADDRESS_SCREEN);
    // });
  }, [userId, userZip, nextScreen, hotCocoaConfig]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!validZipcodeRegex.test(form.current.zip.value)) {
      setError('Please, provide a valid zipcode. Must be 5 digits.');
      return;
    }

    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.AUTO_ADDRESS_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      address: completeAddress,
      user_city: city,
      user_state: state,
      user_zip: form.current.zip.value
    }
    // console.log('CarDetails > ', data)
    updateProfile(data);
    history.push(nextScreen);
  };

  const handleCompleteAddress = (addressValue) => {
    // console.log(addressValue)
    geocodeByAddress(addressValue?.label)
      .then(results => {
        results[0]?.address_components?.forEach(entry => {
          if (entry.types?.[0] === "postal_code") {
            setZip(entry.long_name);
          }
        })
      })
      .catch(error => console.error(error));
    setCompleteAddress(addressValue?.value?.structured_formatting?.main_text)
    const cityState = addressValue?.label?.split(',')
    setState(cityState[cityState.length-2]?.toString().trim())
    setCity(cityState[cityState.length-3]?.toString().trim())
  }


  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={50}>
      <Card.Title className="Auto-Insu-title">{constants.AUTO_APP_TITLE_TEXT}</Card.Title>
      <Form ref={form} onSubmit={onSubmit}>
        <Form.Text className="Form-label mb-4">Complete Address</Form.Text>
        <GooglePlacesAutocomplete
          apiKey={GOOGLE_API_KEY}
          selectProps={{
            completeAddress,
            onChange: handleCompleteAddress,
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['us']
            }
          }}
        />
        <div className="Form-row">
          <input id="city" type="text" className="Form-Date-box mt-2 mb-2" placeholder="City" required
                 defaultValue={city} />
          <input id="state" type="text" className="Form-Date-box mt-2 mb-2" placeholder="State" required
                 defaultValue={state} />
          <input id="zip" maxLength="5" type="text" className="Form-Date-box mt-2 mb-2" required defaultValue={zip} />
        </div>

        <Form.Text className="text-danger">{error}</Form.Text>
        <Button className="Form-continue mt-4" type="submit">Submit</Button>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default CompleteAddress;