/*eslint camelcase: ["error", {properties: "never"}]*/
import axios from 'axios';

import constants from '../utils/constants';

import api from './api';
import config from './config';

// eslint-disable-next-line
export const getUserData = async () => {
  try {
    const { data } = await axios.get(config.IP_API_URL);
    if (data) {
      localStorage.setItem(constants.USER_IP_ADDRESS, data.query);
      localStorage.setItem(constants.IP_ZIP_CODE, data.zip);
      localStorage.setItem(constants.USER_STATE_CODE, data.region);
      localStorage.setItem(constants.USER_CITY, data.city);
      // console.log('Successfully got user location data:', data);
    }
  } catch (error) {
    console.error('API error getting user location data Error:', error);
  }
};

export const getJobs = async (noJobs) => {
  const category = localStorage.getItem(constants.JOB_CATEGORY) || '';
  const zipCode = localStorage.getItem(constants.USER_ZIP_CODE) || '';
  const utmSource = localStorage.getItem(constants.UTM_SOURCE) || '';
  const url = config.CM_URL + `jobs?category=${category}&zip=${zipCode}&max=${noJobs}`;

  try {
    let response = null;
    if (utmSource === constants.PUB_APPCAST) {
      response = await api.getCwData(url);
    } else {
      response = await api.getData(url);
    }
    // console.log('Successfully getting CM Feed Jobs. Response:', response);
    return response;
  } catch (error) {
    console.error('Error getting CM Feed Jobs. Error:', error);
  }
};

export const getProfile = async () => {
  const email = localStorage.getItem(constants.USER_EMAIL) || '';
  const url = config.CM_URL + `user/profile?email=${email}`;

  try {
    const response = await api.getData(url);
    // console.log('Successfully getting user profile. Response:', response);
    return response;
  } catch (error) {
    console.error('Error getting user profile. Error:', error);
  }
};

export const getPrePops =async()=>{
  const userId = localStorage.getItem(constants.USER_ID)
  const jpub=localStorage.getItem(constants.J_PUB) || '';
  const category=localStorage.getItem(constants.JOB_CATEGORY)
  const url = config.CM_URL+`prepop/list?jobbleUserId=${userId}&category=${category}&publisherName=${jpub}`
  try{
    const response=await api.getData(url)
    return response
  }catch(error){
    console.log('Error getting user profile. Error:',error)
  }
}

export const updateProfile = async (data) => {
  const url = config.CM_URL + `user/profile`;

  try {
    if (localStorage.getItem(constants.J_PUB)) {
      data['jpub'] = localStorage.getItem(constants.J_PUB) || null
    }
    const response = await api.putData(url, data);
    // console.log('Successfully updating user profile. Response:', response);
    localStorage.setItem(constants.USER_ID, response.data.user_id)
    return response;
  } catch (error) {
    console.error('Error updating user profile. Error:', error);
  }
};

export const updateProfileStep = async (step) => {
  const url = config.CM_URL + `user/profile`;

  try {
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      step: step
    }
    if (localStorage.getItem(constants.J_PUB)) {
      data['jpub'] = localStorage.getItem(constants.J_PUB)
    }
    const response = await api.putData(url, data);
    // console.log('Successfully updating user profile step. Response:', response);
    return response;
  } catch (error) {
    console.error('Error updating user profile. Error:', error);
  }
};

export const getJobById = async (jobId) => {
  const utmSource = localStorage.getItem(constants.UTM_SOURCE) || '';
  const url = config.CM_URL + `jobs/${jobId}`;
  try {
    let response = null;
    if (utmSource === constants.PUB_APPCAST) {
      response = await api.getCwData(url);
    } else {
      response = await api.getData(url);
    }
    // console.log('Successfully getting CM Jobs By ID Response:', response);
    return response;
  } catch (error) {
    console.error('Error getting CM Jobs By ID Error:', error);
  }
};

export const getHotCocoaScreens = async (publisherName) => {
  const url = config.CM_URL + `publisher/cocoaScreens?jpub=${publisherName}`;
  try {
    const response = await api.getData(url);
    // console.log('Successfully getting publisher screens. Response:', response);
    return response;
  } catch (error) {
    console.error('Error getting publisher screens. Error:', error);
    throw error;
  }
};

export const getJUserInfo = (value) => {
  try {
      if (value.indexOf('juserInfo') !== -1) {
        value = value.substr(value.indexOf('juserInfo'), value.length)
        value = value.replace('juserInfo=', '')
        return value;
      }
      return '';
  } catch (error) {
    console.error('Error getting publisher screens. Error:', error);
    return ''
  }
};

export const postFindImpression = async () => {
  const url = config.CM_URL + `perks/list?email=${localStorage.getItem(constants.USER_EMAIL)}` +
  `&campaignId=${localStorage.getItem(constants.PERK_PUBLISHER_CAMPAIGN_ID)}` +
  `&serviceProvider=${constants.EVERQUOTE_AUTO}&max=3`;

  try {
    const response = await api.getData(url);
    // console.log('Successfully Get Find Impression Response:', response);
    return response;
  } catch (error) {
    console.error('Error updating user profile. Error:', error);
  }
};