import React, { useRef, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { changeBackground, getNextScreen, setSkip } from '../utils/common';
import Button from "react-bootstrap/Button";
import SkipToJob from "../components/SkipToJob";

const CarLessSpent = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const [hideClass, setHideClass] = useState('d-none');
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_CAR_LESS_SPENT_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.AUTO_CAR_LESS_SPENT_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_CAR_LESS_SPENT_SCREEN);
    // });
  }, [userId, nextScreen, hotCocoaConfig]);

  const onSubmit = () => {
    const carInsuranceInterest = form.current.radio.value;
    if (!carInsuranceInterest) {
      setError('Please, select one option.');
      return;
    }
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.AUTO_CAR_LESS_SPENT_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      insurance: {
        car_insurance_interest: carInsuranceInterest
      }
    }
    updateProfile(data);
    let redirectionURL
    if (carInsuranceInterest === "No, I don't like savings") {
      if (hotCocoaConfig) {
        const jsonHotCocoaConfig = JSON.parse(hotCocoaConfig)
        redirectionURL = jsonHotCocoaConfig?.hococoaConfig?.autoinsinterest?.noidontlikesavings;
      } else {
        redirectionURL = constants.HOT_JOB_SCREEN
      }
    }
    if (redirectionURL) {
      if (redirectionURL?.toString()?.includes('http')) {
        window.location.assign(redirectionURL)
      } else {
        history.push(redirectionURL);
      }
    } else {
      history.push(nextScreen);
    }
  };

  const onChangeRadio = (event) => {
    changeBackground(event);
    if (form.current.radio.value === "No, I don't like savings") {
      onSubmit();
    } else {
      setHideClass('');
    }
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={40}>
      <Form ref={form}>
        <Card.Title className="App-subtitle text-center">{constants.STEPS_TITLE}</Card.Title>
        <Card.Title className="App-question mt-4 mb-3">Want to spend less on your car insurance?</Card.Title>
        <Form.Check id="option1" name="radio" type="radio" value="Yes, I like savings" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option1" id="label_option1" name="options" className="Select-option">Yes, I like savings</label>

        <Form.Check id="option2" name="radio" type="radio" value="Maybe, need more info" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option2" id="label_option2" name="options" className="Select-option">Maybe, need more info</label>

        <Form.Check id="option3" name="radio" type="radio" value="I need auto insurance" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option3" id="label_option3" name="options" className="Select-option">I need auto insurance</label>

        <Form.Check id="option4" name="radio" type="radio" value="No, I don't like savings" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option4" id="label_option4" name="options" className="Select-option">No, I don't like savings</label>

        <div className={`Tcpa-Px ${hideClass}`}>
          <label className="mt-2 mb-2">
            By clicking Get My Quotes, I agree to the <a href="https://www.px.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://www.px.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and authorize <a href="https://www.px.com/offer-guidelines/insurance/top-auto-insurance-companies/" target="_blank" rel="noopener noreferrer">insurance companies, their agents and marketing partners</a>
            to contact me about auto insurance and other non-insurance offers by telephone calls, emails and text messages to
            the number and email address I provided above. I agree to receive telemarketing calls and pre-recorded messages
            via an autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state,
            federal or corporate "Do Not Call" list. I understand that my consent is not a condition of purchase of any goods or
            services and that I may revoke my consent at any time. I understand that standard message and data rates may apply. <a href="https://www.px.com/california-privacy-notice/" target="_blank" rel="noopener noreferrer">California Residents.</a>
          </label>
        </div>
        <Button className={`Form-continue mt-4 ${hideClass}`} type="button" onClick={() => onSubmit()}>Get My Quotes</Button>

        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default CarLessSpent;