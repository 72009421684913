import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { getNextScreen, setSkip } from '../utils/common';
import SkipToJob from "../components/SkipToJob";

const EducationConfirm = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.EDU_INTEREST_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const [graduationYearRequired, setGraduationYearRequired] = useState(true);
  const [hideGraduationYear, setHideGraduationYear] = useState('');
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.EDU_STATUS_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.EDU_STATUS_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.EDU_STATUS_SCREEN);
    // });
  }, [nextScreen, hotCocoaConfig]);

  const onSubmit = (event) => {
    event.preventDefault();
    const education = form.current.education.value;
    const graduationYear = form.current.year.value;
    if (!education) {
      setError('Please, select an education level.');
      return;
    }
    if (education !== 'No formal education' && !graduationYear) {
      setError('Please, select a graduation year.');
      return;
    }
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.EDU_STATUS_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      education: {
        education,
        graduation_year: graduationYear !== '' ? graduationYear : null
      }
    }
    // console.log('Confirm Education > ', data)
    updateProfile(data);
    history.push(nextScreen);
  };

  const renderYears = () => {
    let years = [];
    for (let i = 2021; i >= 1950; i--) {
      years.push(i);
    }

    return (
      years.map((year) => {
        return (
          <option key={year} value={year}>{year}</option>
        );
      })
    );
  };

  const handleGraduationChange = (event) => {
    if (event.target.value === 'No formal education') {
      setHideGraduationYear('d-none')
      setGraduationYearRequired(false)
    } else {
      setHideGraduationYear('')
      setGraduationYearRequired(true)
    }
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={40}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="App-subtitle text-center">{constants.STEPS_TITLE}</Card.Title>
        <Card.Title className="App-title mt-4">Confirm your education status</Card.Title>
        <Form.Text className="Form-label">Highest level of education</Form.Text>
        <Form.Control id="education" className="Form-combo mt-2 mb-3" as="select" required onChange={handleGraduationChange}>
          <option value="">Education level</option>
          <option value="No formal education">No formal education</option>
          <option value="Primary education">Primary education</option>
          <option value="Secondary education">Secondary education or high school</option>
          <option value="Bachelor's degree">Bachelor's degree</option>
          <option value="Master's degree">Master's degree</option>
          <option value="Doctorate or higher">Doctorate or higher</option>
        </Form.Control>
        <Form.Text className={`Form-label ${hideGraduationYear}`}>High school graduation year</Form.Text>
        <Form.Control id="year" className={`Form-combo mt-2 mb-3 ${hideGraduationYear}`} as="select" required={graduationYearRequired}>
          <option value="">Choose year</option>
          {renderYears()}
        </Form.Control>
        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Button className="Form-continue mt-4" type="submit">Submit</Button>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default EducationConfirm;