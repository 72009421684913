import React, { useRef, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import CardContainer from '../components/CardContainer';
import {getPrePops, updateProfileStep,getProfile} from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import {getImage, getNextScreen, setSkip, toCamelCase} from '../utils/common';

const JobsOne = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.JOB_LIST_SCREEN}`);
  const form = useRef(null);
  const [advertise, setAdvertise] = useState({});
  const [companyAppender, setCompanyAppender] = useState('');
  const userId = localStorage.getItem(constants.USER_ID)
  const jobCompany = localStorage.getItem(constants.JOB_COMPANY)

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.HOT_JOB_SCREEN)}`);
    setSkip(nextScreen);
    if (userId) {
      updateProfileStep(constants.HOT_JOB_SCREEN).then(()=> {
          // console.log('Userprofile Updated Successfully for screen : ', constants.HOT_JOB_SCREEN);
      });
    }
    if (jobCompany) {
      setCompanyAppender('&jblJobCompany=' + jobCompany)
    }
  }, [nextScreen, userId, jobCompany]);

  useEffect(() => {
    let mounted = true;
    const fetchData = () => {
      getProfile().then((response)=>{
        localStorage.setItem(constants.USER_CITY,response.data.user_city)
        localStorage.setItem(constants.USER_STATE_CODE,response.data.user_state)
      })

      getPrePops().then((response)=>{
        if(response.data['error_code']==='ERR0010') {
            setAdvertise({})
        } else {
          if (mounted) {
              setAdvertise(response.data)
          }
        }
      });
    };

    if (userId) {
        fetchData();
    } else {
        history.push(nextScreen);
    }
    return () => mounted = false;
  }, [nextScreen, userId]);

  const onSubmit = (event) => {
    event.preventDefault();
    history.push(nextScreen);
  };

  const onClickNoThanks = (event) => {
    event.preventDefault();
    history.push(nextScreen);
  };

  const renderAdvertise=()=>{
      const category = localStorage.getItem(constants.JOB_CATEGORY)
      const title = advertise?.title?.toString()?.replace("{CATEGORY}", category);
      const city=localStorage.getItem(constants.USER_CITY)
      const state =localStorage.getItem(constants.USER_STATE_CODE)
      return (
          <div key={advertise?.prepopId}>
              <Row>
                  <Col>
                      <Card.Img src={advertise?.campaignImage === null || advertise?.campaignImage === '' ?
                        getImage('/icons/jobble-job-logo.png') : advertise?.campaignImage} className="Job-image" />
                  </Col>
                  <Col md={9}>
                      <Card.Link href={`${advertise?.campaignUrl}${companyAppender}`} className="mt-1 Job-title">
                          {title}
                      </Card.Link>
                      <Card.Text className="Job-partner">Full/Part-Time</Card.Text>
                  </Col>
                  <Row className="mt-4">
                      <Card.Subtitle className="Job-subtitle">{toCamelCase(city)}, {state}</Card.Subtitle>
                  </Row>
              </Row>
          </div>
      );
  }
  return (
    <CardContainer title={constants.PROFILE_COMPLETE} progress={90}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="Quote-Page-Title">Trending Jobs Near You</Card.Title>
        <Card.Title>While we finish curating your jobs, here are some popular opportunities that match your profile.</Card.Title>
          <Card className={`Job-card mt-3 ${advertise?.title ? '' : 'd-none'}`}>
              <Card.Body>
                  {renderAdvertise()}
              </Card.Body>
          </Card>
        <div className={`Skip-button mt-3 `}>
          <span className="CursorPointer text-decoration-underline" onClick={onClickNoThanks}>No thanks</span>
        </div>
      </Form>
   </CardContainer>
  );
};

export default JobsOne;