// eslint-disable-next-line
import React from 'react';
import '../styles/Header.css';

const Header = () => {
  return (
    <div className="wrapper">
      <div className="Skip" id="skip-div">
        <a href="/" rel="noopener noreferrer" id="skip-link">Skip</a>
      </div>
      <div className="Header">
        <a href="/">
          <img src="/jobble-logo.png" alt="Jobble Logo" className="Header-logo" />
        </a>
      </div>
    </div>
  );
};

export default Header;