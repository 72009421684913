const IP_ZIP_CODE = 'ipZipCode';
const USER_EMAIL = 'userEmail';
const USER_ID = 'userId';
const USER_ZIP_CODE = 'userZipCode';
const USER_IP_ADDRESS = 'userIpAddress';
const USER_STATE_CODE = 'userStateCode';
const USER_CITY = 'userCity';
const USER_PHONE = 'userPhone';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const JOB_CATEGORY = 'jobCategory';
const JOB_CATEGORYLA = 'jobCategoryLA';
const JOB_COMPANY = 'jobCompany';
const JOB_TITLE = 'jobTitle';
const JOB_CITY = 'jobCity';
const JOB_STATE = 'jobState';
const PUBLISHER_SCREENS = 'publisherScreens';
const CW_JOB_ID = 'cwJobId';
const CW_JOB_URL = 'cwJobUrl';
const JOB_CATEGORY_COUNT = 'jobCategoryCount';
const JOB_ALERTS = 'jobAlerts';
const UTM_SOURCE = 'utm_source';
const UTM_CAMPAIGN = 'utm_campaign';
const HOT_COCOA = 'hotcocoa';
const J_PUB = 'jpub';
const J_USER_INFO = 'juserInfo';
const SIGN_UP_SOURCE = 'sign_up_source';
const COLOR_LABEL_BG = '#d0dbe0';
const COLOR_LABEL_BG_CHECKED = '#6decc3';
const COMPLETE_PROFILE = "COMPLETE YOUR PROFILE";
const PROFILE_COMPLETE = "PROFILE COMPLETE!";
const STEPS_TITLE = "Let’s fill out your profile with just a few questions.";
const SINGLE_JOB_URL = "singleJobURL";
const IS_MY_INFO_SELLABLE = 'isMyInfoSellable';
const USER_GENDER = 'userGender';
const AUTO_APP_TITLE_TEXT = "Answer a few more questions so we can save you money on auto insurance.";
const HEALTH_APP_TITLE_TEXT = "Answer a few more questions so we can save you money on health insurance.";
const PUB_APPCAST = "appcast";
const EDU_INTEREST = "educationInterest"
const DEFAULT_SCREEN = "jobtype,userinfo,usermobile,edustatus,eduinterest,eduoffer,autoinsinterest,autoinsinfo,autoinshomeowner,autoinsdui,autoinsaddress,autoinscreditrank,autoinsoffer,hotjob,joblist"
const ZIP_LA = 'zipLA'
const PERK_PUBLISHER_CAMPAIGN_ID = 'perksPublisherCampaignId';
const HOT_COCOA_CONFIG = 'hotcocoaConfig';
const EVERQUOTE_AUTO = 'everquoteauto';

const JOB_TYPE_SCREEN = "jobtype"
const USER_INFO_SCREEN = "userinfo"
const USER_MOBILE_SCREEN = "usermobile"
const EDU_STATUS_SCREEN = "edustatus"
const EDU_INTEREST_SCREEN = "eduinterest"
const EDU_OFFER_SCREEN = "eduoffer"
const AUTO_CAR_REQUIRED_SCREEN = "autoowner"
const AUTO_CAR_LESS_SPENT_SCREEN = "autoinsinterest"
const AUTO_CAR_DETAILS_SCREEN = "autoinsinfo"
const AUTO_HOME_OWNER_SCREEN = "autoinshomeowner"
const AUTO_DUI_SCREEN = "autoinsdui"
const AUTO_ADDRESS_SCREEN = "autoinsaddress"
const AUTO_CREDIT_RATINGS_SCREEN = "autoinscreditrank"
const AUTO_OFFER_SCREEN = "autoinsoffer"
const HOT_JOB_SCREEN = "hotjob"
const JOB_LIST_SCREEN = "joblist"
const BIRTH_YEAR = "birthYear"
const BIRTH_MONTH = "birthMonth"
const BIRTH_DAY = "birthDay"

const JOB_CATEGORIES_LIST = [
  {value: 'Finance', name: 'Banking & Financial Services'},
  {value: 'Construction', name: 'Construction'},
  {value: 'Customer Service', name: 'Customer Service'},
  {value: 'Delivery', name: 'Delivery'},
  {value: 'Healthcare', name: 'Healthcare & Medical'},
  {value: 'Hospitality', name: 'Hospitality & Tourism'},
  {value: 'Manufacturing', name: 'Manufacturing'},
  {value: 'Real Estate', name: 'Real Estate'},
  {value: 'Food', name: 'Restaurant & Food Services'},
  {value: 'Retail', name: 'Retail & Consumer Products'},
  {value: 'Sales', name: 'Sales & Marketing'},
  {value: 'Tech', name: 'Technology'},
  {value: 'Transportation', name: 'Transportation, Logistics, & Storage'},
  {value: 'Warehouse', name: 'Warehouse'},
  {value: 'Other', name: 'Other'}
]

const AUTO_INSURANCE_PROVIDER = [
  "AAA / Auto Club",
  "Allstate",
  "Farm Bureau",
  "Farmers",
  "GEICO",
  "Liberty Mutual",
  "Nationwide",
  "Progressive",
  "Safeco",
  "State Farm",
  "The Hartford",
  "USAA",
  "Other"
]

module.exports = {
  IP_ZIP_CODE,
  USER_EMAIL,
  USER_ID,
  USER_ZIP_CODE,
  USER_IP_ADDRESS,
  USER_STATE_CODE,
  USER_CITY,
  USER_PHONE,
  FIRST_NAME,
  LAST_NAME,
  JOB_CATEGORY,
  JOB_COMPANY,
  JOB_TITLE,
  JOB_CITY,
  JOB_STATE,
  JOB_CATEGORY_COUNT,
  JOB_ALERTS,
  PUBLISHER_SCREENS,
  UTM_SOURCE,
  UTM_CAMPAIGN,
  HOT_COCOA,
  COLOR_LABEL_BG,
  COLOR_LABEL_BG_CHECKED,
  STEPS_TITLE,
  COMPLETE_PROFILE,
  PROFILE_COMPLETE,
  SINGLE_JOB_URL,
  IS_MY_INFO_SELLABLE,
  USER_GENDER,
  AUTO_APP_TITLE_TEXT,
  AUTO_INSURANCE_PROVIDER,
  HEALTH_APP_TITLE_TEXT,
  PUB_APPCAST,
  CW_JOB_ID,
  CW_JOB_URL,
  EDU_INTEREST,
  DEFAULT_SCREEN,
  J_PUB,
  J_USER_INFO,
  SIGN_UP_SOURCE,
  JOB_CATEGORIES_LIST,
  JOB_TYPE_SCREEN,
  USER_INFO_SCREEN,
  USER_MOBILE_SCREEN,
  EDU_STATUS_SCREEN,
  EDU_INTEREST_SCREEN,
  EDU_OFFER_SCREEN,
  AUTO_CAR_REQUIRED_SCREEN,
  AUTO_CAR_LESS_SPENT_SCREEN,
  AUTO_CAR_DETAILS_SCREEN,
  AUTO_HOME_OWNER_SCREEN,
  AUTO_DUI_SCREEN,
  AUTO_ADDRESS_SCREEN,
  AUTO_CREDIT_RATINGS_SCREEN,
  AUTO_OFFER_SCREEN,
  HOT_JOB_SCREEN,
  JOB_LIST_SCREEN,
  ZIP_LA,
  BIRTH_YEAR,
  BIRTH_MONTH,
  BIRTH_DAY,
  PERK_PUBLISHER_CAMPAIGN_ID,
  EVERQUOTE_AUTO,
  HOT_COCOA_CONFIG,
  JOB_CATEGORYLA
};