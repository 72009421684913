// eslint-disable-next-line
import React from 'react';

const PolicyLinks = () => {
  return (
    <span>
      Jobble’s <a href="https://kb.jobble.com/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of Service</a>, <a href="https://kb.jobble.com/privacy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>, and <a href="https://kb.jobble.com/cookies/" target="_blank" rel="noopener noreferrer">Cookie Policy</a>,
    </span>
  );
};

export default PolicyLinks;