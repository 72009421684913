import React, { useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import CardContainer from '../components/CardContainer';
import constants from '../utils/constants';
import { getNextScreen, setSkip } from '../utils/common';
import Button from "react-bootstrap/Button";
import history from "../utils/history";
import SkipToJob from "../components/SkipToJob";

const EducationViewQuote = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const EDUCATION_URL = `${process.env.REACT_APP_CM_EDUCATION_URL}`;
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.EDU_OFFER_SCREEN)}`);
    setSkip(nextScreen);
  }, [nextScreen, hotCocoaConfig]);

  const handleOnClickViewQuote = () => {
    history.push(nextScreen);
    const newWindow = window.open(`${EDUCATION_URL}?jblUserId=${userId}`, '_blank')
    if (newWindow) newWindow.opener = null
  }

  const handleOnClickViewLater = () => {
    history.push(nextScreen);
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={80}>
      <Card.Title className="Quote-Page-Title">Get Matched!</Card.Title>
      <Card.Title>Find an educational program that will help grow your career.</Card.Title>
      <Button className="Form-continue mt-3" type="submit" onClick={() => handleOnClickViewQuote()}>Search Schools</Button>
      <div className="Skip-button mt-3">
        <span className="CursorPointer text-decoration-underline" onClick={handleOnClickViewLater}>View Later, Take Me to Jobs</span>
      </div>
      <SkipToJob/>
    </CardContainer>
  );
};

export default EducationViewQuote;