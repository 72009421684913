import React, { useRef, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { changeBackground, getNextScreen, setSkip } from '../utils/common';
import SkipToJob from "../components/SkipToJob";

const OwnHome = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);
  
  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_HOME_OWNER_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.AUTO_HOME_OWNER_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_HOME_OWNER_SCREEN);
    // });
  }, [userId, nextScreen, hotCocoaConfig]);

  const onSubmit = () => {
    const isHomeOwner = form.current.radio.value;
    if (!isHomeOwner) {
      setError('Please, select one option.');
      return;
    }
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.AUTO_HOME_OWNER_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      insurance: {
        is_home_owner: isHomeOwner === "Yes" ? true : false
      }
    }
    updateProfile(data);
    history.push(nextScreen);
  };

  const onChangeRadio = (event) => {
    changeBackground(event);
    onSubmit();
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={50}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="Auto-Insu-title">{constants.AUTO_APP_TITLE_TEXT}</Card.Title>

        <Card.Title className="App-question mt-4 mb-3">Do you own a home?</Card.Title>
        <Form.Check id="option1" name="radio" type="radio" value="Yes" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option1" id="label_option1" name="options" className="Select-option">Yes</label>

        <Form.Check id="option2" name="radio" type="radio" value="No" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option2" id="label_option2" name="options" className="Select-option">No</label>
        
        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default OwnHome;