import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import history from '../utils/history';
import constants from '../utils/constants';
import { formatPhoneNumbers, validZipcodeRegex } from '../utils/validate';
import { setSkip, toTitleCase } from '../utils/common';
import { getJobById, getUserData, updateProfile } from "../api/service";

const Home = () => {
  const form = useRef(null);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const ipZipCode = localStorage.getItem(constants.IP_ZIP_CODE) || '';
  const [hideClass, setHideClass] = useState('');
  const [hideCategory, setHideCategory] = useState('d-none');
  const [jobCategoryLA, setJobCategoryLA] = useState(localStorage.getItem(constants.JOB_CATEGORYLA) || '');
  const [hideOtherJobCategory, setHideOtherJobCategory] = useState('d-none');
  const [hideCwTitle, setHideCwTitle] = useState('d-none');
  const jobCategory = localStorage.getItem(constants.JOB_CATEGORY);
  const jobCompany = localStorage.getItem(constants.JOB_COMPANY);
  const jobTitle = localStorage.getItem(constants.JOB_TITLE);
  const jobCity = localStorage.getItem(constants.JOB_CITY);
  const jobState = localStorage.getItem(constants.JOB_STATE);
  const cwJobId = localStorage.getItem(constants.CW_JOB_ID);
  const jobCategoryImage = jobCategory ? toTitleCase(jobCategory)?.replaceAll(' ', '-') : '';
  const [cwJobUrl, setCwJobUrl] = useState('');

  useEffect(() => {
    setSkip('/');
    const getCategories = () => {
      if (jobCategory !== '') {
        setHideClass('d-none');
        //Checking for is it ClickWall Flow or not? by checking utm_source=appcast
        if (localStorage.getItem(constants.UTM_SOURCE) === constants.PUB_APPCAST) {
          setHideCategory('d-none');
          setHideCwTitle('');
        } else {
          setHideCategory('');
          setHideCwTitle('d-none');
        }
      } else {
        if (localStorage.getItem(constants.UTM_SOURCE) === constants.PUB_APPCAST) {
          setHideClass('');
          setHideCategory('d-none');
          setHideCwTitle('');
        }
      }
      setCategories(constants.JOB_CATEGORIES_LIST);
    }

    let mounted = true;

    const fetchData = () => {
      getCategories()
      getUserData().then( ()=> {
        // form.current.userZipcode.value = localStorage.getItem(constants.IP_ZIP_CODE) || '';
      });

      if (cwJobId && cwJobId !== '') {
        getJobById(cwJobId)
            .then((response) => {
              if (response.data.data && mounted) {
                localStorage.setItem(constants.CW_JOB_URL, response?.data?.data[0]?.url)
                setCwJobUrl(response?.data?.data[0]?.url);
                localStorage.setItem(constants.JOB_CATEGORY, response?.data?.data[0]?.minor_category)
                localStorage.setItem(constants.JOB_COMPANY, response?.data?.data[0]?.company)
              }
            })
            .catch((error) => {
              console.error(error);
            });
      } else {
        setCwJobUrl(`/${constants.JOB_LIST_SCREEN}`);
      }
    };

    fetchData();
    return () => mounted = false;
  }, [jobCategory, jobCompany, jobTitle, cwJobId, cwJobUrl, ipZipCode]);

  useEffect(() => {
    const category = form.current.jobCategory;
    category.value = localStorage.getItem(constants.JOB_CATEGORYLA) ?
      localStorage.getItem(constants.JOB_CATEGORYLA) :
      localStorage.getItem(constants.JOB_CATEGORY);
  }, [categories]);

  useEffect(()=>{
    const saveLeadAcquisition = () => {
      let jpub = localStorage.getItem(constants.J_PUB)
      let email = localStorage.getItem(constants.USER_EMAIL);
      let jUserInfo = localStorage.getItem(constants.J_USER_INFO)
      let userId = localStorage.getItem(constants.USER_ID)
      if (email && !userId) {
        let birthDate;
        if (localStorage.getItem(constants.BIRTH_YEAR) &&
          localStorage.getItem(constants.BIRTH_MONTH) &&
          localStorage.getItem(constants.BIRTH_DAY)) {
          birthDate = `${localStorage.getItem(constants.BIRTH_YEAR)}-${localStorage.getItem(constants.BIRTH_MONTH)}-${localStorage.getItem(constants.BIRTH_DAY)}`
        }
        let mobileNumber;
        if (localStorage.getItem(constants.USER_PHONE)) {
          mobileNumber = formatPhoneNumbers(localStorage.getItem(constants.USER_PHONE));
        }
        let data = {
          ip_zip: localStorage.getItem(constants.IP_ZIP_CODE) || '',
          user_zip: localStorage.getItem(constants.USER_ZIP_CODE) || '',
          first_name: localStorage.getItem(constants.FIRST_NAME),
          last_name: localStorage.getItem(constants.LAST_NAME),
          email,
          mobile: mobileNumber,
          birth_date: birthDate || '',
          sign_up_source: jpub || constants.HOT_COCOA
        }
        form.current.userZipcode.value = localStorage.getItem(constants.USER_ZIP_CODE);
        if (localStorage.getItem(constants.JOB_CATEGORYLA)) {
          const feed = {
            preferred_job_category: localStorage.getItem(constants.JOB_CATEGORYLA) || '',
            job_category_count: localStorage.getItem(constants.JOB_CATEGORY_COUNT) || ''
          }
          data['feed'] = feed
        }
        if (localStorage.getItem(constants.USER_GENDER)) {
          const insurance =  {
            gender: localStorage.getItem(constants.USER_GENDER)
          }
          data['insurance'] = insurance
        }
        if (jpub) {
          data['jpub'] = jpub
        }
        updateProfile(data).then((response) => {
          if (response?.data) {
            const result = response.data
            form.current.userZipcode.value = result?.user_zip;
          }
        });
      } else if (jpub && jUserInfo) {
        const data = {
          jpub: jpub,
          juserInfo: jUserInfo
        }
        updateProfile(data).then((response) => {
          if (response?.data) {
            const result = response.data
            localStorage.setItem(constants.JOB_CATEGORYLA, result?.feed?.preferred_job_category || '')
            localStorage.setItem(constants.IP_ZIP_CODE, result?.user_zip || '')
            localStorage.setItem(constants.USER_ZIP_CODE, result?.user_zip || '')
            localStorage.setItem(constants.FIRST_NAME, result?.first_name || '')
            localStorage.setItem(constants.LAST_NAME, result?.last_name || '')
            localStorage.setItem(constants.USER_EMAIL, result?.email || '')
            localStorage.setItem(constants.USER_PHONE, result?.mobile || '')
            localStorage.setItem(constants.USER_GENDER, result?.insurance?.gender?.toLowerCase() || '')
            setJobCategoryLA(result?.feed?.preferred_job_category || '')
            form.current.userZipcode.value = result?.user_zip || '';

            let birthDate = result?.birth_date?.split('-')
            localStorage.setItem(constants.BIRTH_YEAR, birthDate[0] || '')
            localStorage.setItem(constants.BIRTH_MONTH, birthDate[1] || '')
            localStorage.setItem(constants.BIRTH_DAY, birthDate[2] || '')
          }
        });
      }
    };

    saveLeadAcquisition();
  },[]);


  const onSubmit = (event) => {
    event.preventDefault();
    const category = form.current.jobCategory;
    let jobCategoryValue = category.value;

    if (!jobCategoryValue) {
      jobCategoryValue = jobCategory;
    }

    if (!jobCategoryValue) {
      setError('Please, select a job category.');
      return;
    } else {
      if (jobCategoryValue === 'Other') {
        jobCategoryValue = form.current.otherJobCategory.value;
        if (!jobCategoryValue) {
          setError('Please, specify other job category.');
          return;
        }
      }
    }

    const userZipcode = form.current.userZipcode.value;
    if (!userZipcode || !validZipcodeRegex.test(userZipcode)) {
      setError('Please, provide a valid zipcode. Must be 5 digits.');
      return;
    }
    localStorage.setItem(constants.JOB_CATEGORY, jobCategoryValue);
    localStorage.setItem(constants.USER_ZIP_CODE, userZipcode);
    history.push(`/${constants.USER_INFO_SCREEN}`);
  }

  const renderCategories = () => {
    return (
      categories.map((category) => {
        return (
          <option id={category.value} key={category.value} value={category.value}>{category.name}</option>
        );
      })
    );
  }

  function getCategoryByKey(key) {
    if (key && key !== '') {
      let category = constants.JOB_CATEGORIES_LIST.find(x => x.value.toLowerCase() === key.toLowerCase())?.name;
      return category ? category : jobCategory;
    }
  }

  const onChangeRadio = () => {
    const jobCategory = form.current.jobCategory.value;
    if (jobCategory === 'Other') {
      setHideOtherJobCategory('');
    } else {
      setHideOtherJobCategory('d-none');
    }
    setJobCategoryLA(jobCategory);
  }

  const addDefaultSrc = () => {
    const categoryImage = document.getElementById("category-image");
    categoryImage.src = "/icons/categories/Green/Admin.png";
  }

  return (
  <CardContainer title="DISCOVER THE RIGHT FIT">
    <Form ref={form} onSubmit={onSubmit}>
      <div className={`${hideCwTitle} text-center`}>
        <Card.Title className={`${jobCompany === '' ? 'd-none' : ''} App-title LineHeight mt-2`}>{jobCompany}</Card.Title>
        <Card.Title className={`${jobTitle === '' ? 'd-none' : ''} App-title LineHeight mt-2`}>{jobTitle}</Card.Title>
        <Card.Title className={`${jobCity === '' || jobState === '' ? 'd-none' : ''} App-title LineHeight mt-2`}>{jobCity}, {jobState}</Card.Title>
      </div>

      <Form.Text className={`Form-label ${hideClass}`}>Job Type</Form.Text>
      <Form.Control id="jobCategory" className={`Form-combo mt-2 mb-3 ${hideClass}`} as="select" onChange={onChangeRadio} value={jobCategoryLA}>
        <option value="">Choose an option</option>
        {renderCategories()}
      </Form.Control>

      <div className={`${hideOtherJobCategory}`}>
        <Form.Text className="Form-label">Job Category</Form.Text>
        <input id="otherJobCategory" type="text" className="Form-box mt-2 mb-4" placeholder="Job Category" />
      </div>

      <div className={`text-center ${hideCategory}`}>
        <img id="category-image" src={`/icons/categories/Green/${jobCategoryImage}.png`} alt={jobCategory} width="100" onError={() => addDefaultSrc()} />
        <Card.Title className="App-title LineHeight mt-2">Find {getCategoryByKey(jobCategory)} Jobs Near You</Card.Title>
      </div>

      <Form.Text className="Form-label">Zip Code</Form.Text>
      <input id="userZipcode" type="text" maxLength="5" className="Form-box mt-2 mb-4" defaultValue={ipZipCode} placeholder="Zip Code" required />

      <div className={`Text-container ${hideCategory}`}>
        <div className="">
          <Row>
            <Col>
              <img src="/icons/checkbox.png" alt="$14 - $35/hr" className="mb-1" />
              <label className="custom-control-label small">
                $14 - $35/hr
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src="/icons/checkbox.png" alt="$14 - $35/hr" className="mb-1" />
              <label className="custom-control-label small">
              Applicants needed
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src="/icons/checkbox.png" alt="$14 - $35/hr" className="mb-1" />
              <label className="custom-control-label small">
              Positions available
              </label>
            </Col>
          </Row>
        </div>  
      </div>
      <Form.Text className="text-danger">{error}</Form.Text>
      <Button className="Form-continue mt-2" type="submit">Submit</Button>
      <Policy />
    </Form>
    <div className={`Skip-To-Jobs`}>
      <a href={cwJobUrl} rel="noopener noreferrer" id="skip-link">Skip to job</a>
    </div>
  </CardContainer>
  );
};

export default Home;