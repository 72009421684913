import React, { Suspense, useEffect } from 'react';

import queryString from 'query-string';

import { Router, Switch, Route } from 'react-router-dom';

import { getJUserInfo, getUserData } from './api/service';

import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import Home from './pages/Home';
import Email from './pages/Email';
import Mobile from './pages/Mobile';
import EducationConfirm from './pages/EducationConfirm';
import EducationExplore from './pages/EducationExplore';
import JobsOne from './pages/JobsOne';
import JobsThree from './pages/JobsThree';
import constants from './utils/constants';
import history from './utils/history';
import { pad, setGender, toTitleCase } from './utils/common';
import EducationViewQuote from "./pages/EducationViewQuote";
import CarRequired from "./pages/CarRequired";
import CarLessSpent from "./pages/CarLessSpent";
import CarDetails from "./pages/CarDetails";
import OwnHome from "./pages/OwnHome";
import Dui from "./pages/Dui";
import CreditRating from "./pages/CreditRating";
import ViewQuote from "./pages/ViewQuote";
import CompleteAddress from "./pages/CompleteAddress";
import LandingPage from "./pages/LandingPage";

const App = () => {

  useEffect(() => {
    getUserData();
  }, []);

  const setDocTitle = () => {
    document.title = `Find Jobs | Jobble`;
  };

  const renderHome = (routerProps) => {
    localStorage.clear();
    const parsed = queryString.parse(routerProps.location.search);
    if (parsed.utm_source) {
      localStorage.setItem(constants.UTM_SOURCE, parsed.utm_source);
    }
    if (parsed.utm_campaign) {
      localStorage.setItem(constants.UTM_CAMPAIGN, parsed.utm_campaign);
    }
    if (parsed.category) {
      localStorage.setItem(constants.JOB_CATEGORY, toTitleCase(parsed.category));
    } else {
      localStorage.setItem(constants.JOB_CATEGORY, '');
    }
    if (parsed.company) {
      localStorage.setItem(constants.JOB_COMPANY, toTitleCase(parsed.company));
    } else {
      localStorage.setItem(constants.JOB_COMPANY, '');
    }
    if (parsed.title) {
      localStorage.setItem(constants.JOB_TITLE, toTitleCase(parsed.title));
    } else {
      localStorage.setItem(constants.JOB_TITLE, '');
    }
    if (parsed.city) {
      localStorage.setItem(constants.JOB_CITY, toTitleCase(parsed.city));
    } else {
      localStorage.setItem(constants.JOB_CITY, '');
    }
    if (parsed.state) {
      localStorage.setItem(constants.JOB_STATE, toTitleCase(parsed.state));
    } else {
      localStorage.setItem(constants.JOB_STATE, '');
    }
    if (parsed.jobid) {
      localStorage.setItem(constants.CW_JOB_ID, parsed.jobid?.toString());
    } else {
      localStorage.setItem(constants.CW_JOB_ID, '');
    }
    if (parsed.jpub) {
      localStorage.setItem(constants.J_PUB, parsed.jpub?.toString());
    }
    if (parsed.sign_up_source) {
      localStorage.setItem(constants.SIGN_UP_SOURCE, parsed.sign_up_source?.toString());
    }
    localStorage.setItem(constants.JOB_CATEGORY_COUNT, '0');

    const juserInfo = getJUserInfo(routerProps.location.search);
    if (juserInfo) {
      localStorage.setItem(constants.J_USER_INFO, juserInfo.toString());
    }
    //Getting Parameters For PrePop
    if (parsed.f) {
      localStorage.setItem(constants.FIRST_NAME, parsed?.f?.toString());
    }
    if (parsed.l) {
      localStorage.setItem(constants.LAST_NAME, parsed?.l?.toString());
    }
    if (parsed.e) {
      localStorage.setItem(constants.USER_EMAIL, parsed?.e?.toString());
    }
    if (parsed.g) {
      setGender(parsed?.g);
    }
    if (parsed.c) {
      localStorage.setItem(constants.JOB_CATEGORYLA, parsed?.c?.toString());
    }
    if (parsed?.z && parsed.z?.toString()?.length === 5) {
      localStorage.setItem(constants.USER_ZIP_CODE, parsed?.z?.toString());
    }
    if (parsed.d) {
      const birthDate = parsed?.d?.toString()?.split('-')
      if (birthDate?.length === 3) {
        if (birthDate[0]?.length === 4 && !isNaN(Number(birthDate[0]))) {
          localStorage.setItem(constants.BIRTH_YEAR, birthDate[0])
        }

        if (birthDate[1]?.length >= 1 && birthDate[1]?.length <= 2 && !isNaN(Number(birthDate[1]))) {
          localStorage.setItem(constants.BIRTH_MONTH, pad(birthDate[1]?.toString()))
        }

        if (birthDate[2]?.length >= 1 && birthDate[2]?.length <= 2 && !isNaN(Number(birthDate[2]))) {
          localStorage.setItem(constants.BIRTH_DAY, pad(birthDate[2]?.toString()))
        }
      }
    }
    if (parsed?.m && parsed.m?.toString()?.length === 10) {
      localStorage.setItem(constants.USER_PHONE, parsed?.m?.toString());
    }
    return <LandingPage/>
  };

  return (
    <div className="App-background">
      {setDocTitle()}
      <Header />
      <div className="App-container">
        <Router history={history}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/" render={routerProps => renderHome(routerProps)} />
              <Route exact path={`/${constants.JOB_TYPE_SCREEN}`} component={Home} />
              <Route exact path={`/${constants.USER_INFO_SCREEN}`} component={Email} />
              <Route exact path={`/${constants.USER_MOBILE_SCREEN}`} component={Mobile} />

              {/* Education Screens */}
              <Route exact path={`/${constants.EDU_STATUS_SCREEN}`} component={EducationConfirm} />
              <Route exact path={`/${constants.EDU_INTEREST_SCREEN}`} component={EducationExplore} />
              <Route exact path={`/${constants.EDU_OFFER_SCREEN}`} component={EducationViewQuote} />

              {/* Health Screens */}
              {/*<Route exact path="/4" component={HealthInterest} />*/}
              {/*<Route exact path="/5" component={Gender} />*/}
              {/*<Route exact path="/6" component={HealthCoverage} />*/}
              {/*<Route exact path="/7" component={Smoke} />*/}
              {/*<Route exact path="/8" component={Income} />*/}

              {/* Auto Screens */}
              <Route exact path={`/${constants.AUTO_CAR_REQUIRED_SCREEN}`} component={CarRequired} />
              <Route exact path={`/${constants.AUTO_CAR_LESS_SPENT_SCREEN}`} component={CarLessSpent} />
              <Route exact path={`/${constants.AUTO_CAR_DETAILS_SCREEN}`} component={CarDetails} />
              <Route exact path={`/${constants.AUTO_HOME_OWNER_SCREEN}`} component={OwnHome} />
              <Route exact path={`/${constants.AUTO_DUI_SCREEN}`} component={Dui} />
              <Route exact path={`/${constants.AUTO_ADDRESS_SCREEN}`} component={CompleteAddress} />
              <Route exact path={`/${constants.AUTO_CREDIT_RATINGS_SCREEN}`} component={CreditRating} />
              <Route exact path={`/${constants.AUTO_OFFER_SCREEN}`} component={ViewQuote} />

              {/* Jobs Screens */}
              <Route exact path={`/${constants.HOT_JOB_SCREEN}`} component={JobsOne} />
              <Route exact path={`/${constants.JOB_LIST_SCREEN}`} component={JobsThree} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    </div>
  );
};

export default App;
