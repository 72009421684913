import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { validEmailRegex } from '../utils/validate';
import { getNextScreen, setSkip } from '../utils/common';
import PolicyLinks from "../components/PolicyLinks";
import LoadingSpinner from "../components/LoadingSpinner";

const Email = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.USER_MOBILE_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const [gender, setGender] = useState(localStorage.getItem(constants.USER_GENDER) || '');
  const firstName = localStorage.getItem(constants.FIRST_NAME) || '';
  const lastName = localStorage.getItem(constants.LAST_NAME) || '';
  const email = localStorage.getItem(constants.USER_EMAIL) || '';
  const zipCode  = localStorage.getItem(constants.USER_ZIP_CODE) || 'you';
  const JOBS_TITLE = `${localStorage.getItem(constants.JOB_CATEGORY) || ''} Jobs Near ${zipCode}`;
  const cwJobUrl = localStorage.getItem(constants.CW_JOB_URL);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);
  const [hideSkipToJob, setHideSkipToJob] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setSkip('/');
    window.$('#inline-radio-1').attr('required', 'required');
  }, [cwJobUrl]);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.USER_INFO_SCREEN)}`);
    // console.log(hotCocoaConfig)
    if (hotCocoaConfig) {
      const jsonHotCocoaConfig = JSON?.parse(hotCocoaConfig)
      // console.log(jsonHotCocoaConfig)
      if (jsonHotCocoaConfig?.hococoaConfig?.global?.skiptojob?.toString() === 'false') {
        setHideSkipToJob('d-none');
      }
    }
  }, [nextScreen, hotCocoaConfig]);

  const renderSkipToJobUrl = () => {
    let skipToJobUrl = `${constants.JOB_LIST_SCREEN}`;
    if (cwJobUrl) {
      skipToJobUrl = cwJobUrl;
    }
    return skipToJobUrl;
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const email = form.current.email.value;
    if (!email || !(email.length > 0) || !validEmailRegex.test(email)) {
      setError('Please, provide a valid email address.');
      return;
    }
    localStorage.setItem(constants.USER_GENDER, gender)
    localStorage.setItem(constants.USER_EMAIL, email);
    const data = {
      ip: localStorage.getItem(constants.USER_IP_ADDRESS) || '',
      ip_zip: localStorage.getItem(constants.IP_ZIP_CODE) || '',
      user_zip: localStorage.getItem(constants.USER_ZIP_CODE) || '',
      first_name: form.current.firstName.value,
      last_name: form.current.lastName.value,
      email,
      is_email_alert_subscribed: form.current.jobAlerts.checked,
      step: constants.USER_INFO_SCREEN,
      jornaya_leadid: form.current.leadid_token.value,
      utm_source: localStorage.getItem(constants.UTM_SOURCE) || '',
      utm_campaign: localStorage.getItem(constants.UTM_CAMPAIGN) || '',
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      is_my_info_sellable: localStorage.getItem(constants.IS_MY_INFO_SELLABLE),
      feed: {
        preferred_job_category: localStorage.getItem(constants.JOB_CATEGORY) || '',
        job_category_count: localStorage.getItem(constants.JOB_CATEGORY_COUNT) || ''
      },
      insurance: {
        gender: gender
      }
    }

    setShowLoading(true);
    updateProfile(data).then( () => {
      history.push(nextScreen);
      setShowLoading(false);
    });

  };

  const onChangeRadio = (event) => {
    setGender(event.target.value);
    window.$('#inline-radio-1').removeAttr('required');
  }

  return (
    <CardContainer title={JOBS_TITLE}>
      <Form ref={form} onSubmit={onSubmit} className={showLoading ? 'd-none' : ''}>
        <Card.Title className="App-title mt-4">Welcome!</Card.Title>
        <Card.Title className="App-question mb-3">Enter your name and email to continue</Card.Title>

        {['radio'].map((type) => (
          <div key={`inline-${type}`} className="mb-3" id="gender">
            <Form.Check
              inline
              label="Mr."
              name="group1"
              value="male"
              defaultChecked={localStorage.getItem(constants.USER_GENDER) === "male"}
              type={type}
              id={`inline-${type}-1`}
              onChange={onChangeRadio}
            />
            <Form.Check
              inline
              label="Ms./Mrs."
              name="group1"
              value="female"
              defaultChecked={localStorage.getItem(constants.USER_GENDER) === "female"}
              type={type}
              id={`inline-${type}-2`}
              onChange={onChangeRadio}
            />
            <Form.Check
              inline
              label="Other"
              name="group1"
              value="other"
              defaultChecked={(localStorage.getItem(constants.USER_GENDER) === "other") || (localStorage.getItem(constants.USER_GENDER) === "")}
              type={type}
              id={`inline-${type}-3`}
              onChange={onChangeRadio}
            />
          </div>
        ))}

        <Form.Text className="Form-label">First name</Form.Text>
        <input id="firstName" type="text" className="Form-box mt-2 mb-2" defaultValue={firstName} placeholder="First Name" required />

        <Form.Text className="Form-label">Last name</Form.Text>
        <input id="lastName" type="text" className="Form-box mt-2 mb-2" placeholder="Last Name" defaultValue={lastName} required />

        <Form.Text className="Form-label">Email address</Form.Text>
        <input id="email" type="email" className="Form-box mt-2 mb-2" defaultValue={email} placeholder="Email" required />

        <div className="custom-control custom-checkbox Checkbox-container">
          <input type="checkbox" className="custom-control-input mt-1 mb-2" id="jobAlerts" defaultChecked={true}/>
          <label className="custom-control-label small" htmlFor="jobAlerts">
            Yes! I want daily email alerts.
          </label>
        </div>
        <div>
          <label className="policy mt-2 mb-2">
            By submitting this form, you are accepting <PolicyLinks/>
          </label>
        </div>
        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Button className="Form-continue mt-4" type="submit">Submit</Button>
        <Policy />
        <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
      </Form>
      <LoadingSpinner showLoader={showLoading} />
      <div className={`Skip-To-Jobs ${hideSkipToJob}`}>
        <a href={renderSkipToJobUrl()} rel="noopener noreferrer" id="skip-link">Skip to job</a>
      </div>
    </CardContainer>
  );
};

export default Email;