import React, { useRef, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { changeBackground, getNextScreen, setSkip } from '../utils/common';
import SkipToJob from "../components/SkipToJob";

const CreditRating = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.HOT_JOB_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const userId = localStorage.getItem(constants.USER_ID);
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.AUTO_CREDIT_RATINGS_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.AUTO_CREDIT_RATINGS_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.AUTO_CREDIT_RATINGS_SCREEN);
    // });
  }, [userId, nextScreen, hotCocoaConfig]);

  const onSubmit = () => {
    const credit = form.current.radio.value;
    if (!credit) {
      setError('Please, select one option.');
      return;
    }
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.AUTO_CREDIT_RATINGS_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      insurance: {
        driver_zero_credit: credit
      }
    }
    // console.log('Driver Zero Credit > ', data)
    updateProfile(data);
    history.push(nextScreen);
  };

  const onChangeRadio = (event) => {
    changeBackground(event);
    onSubmit();
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={80}>
      <Form ref={form} onSubmit={onSubmit}>
        <Card.Title className="Auto-Insu-title">{constants.AUTO_APP_TITLE_TEXT}</Card.Title>

        <Card.Title className="App-question mt-4 mb-3">What best describes your credit rating?</Card.Title>
        <Form.Check id="option1" name="radio" type="radio" value="Excellent" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option1" id="label_option1" name="options" className="Select-option">Excellent</label>

        <Form.Check id="option2" name="radio" type="radio" value="Good" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option2" id="label_option2" name="options" className="Select-option">Good</label>

        <Form.Check id="option3" name="radio" type="radio" value="Average" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option3" id="label_option2" name="options" className="Select-option">Average</label>

        <Form.Check id="option4" name="radio" type="radio" value="Poor" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option4" id="label_option4" name="options" className="Select-option">Poor</label>

        {/*<Form.Check id="option5" name="radio" type="radio" value="no credit" className="checkbox-hidden" onChange={onChangeRadio} />*/}
        {/*<label htmlFor="option5" id="label_option5" name="options" className="Select-option">No Credit</label>*/}

        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default CreditRating;