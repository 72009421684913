import React, { useRef, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import CardContainer from '../components/CardContainer';
import Policy from '../components/Policy';
import { getJobs, updateProfile } from '../api/service';
import history from '../utils/history';
import constants from '../utils/constants';
import { changeBackground, getNextScreen, setSkip } from '../utils/common';
import SkipToJob from "../components/SkipToJob";

const EducationExplore = () => {
  const [nextScreen, setNextScreen] = useState(`/${constants.EDU_OFFER_SCREEN}`);
  const form = useRef(null);
  const [error, setError] = useState('');
  const [hideClass, setHideClass] = useState('d-none');
  const [jobUrl, setJobUrl] = useState('#');
  const hotCocoaConfig = localStorage.getItem(constants.HOT_COCOA_CONFIG);

  useEffect(() => {
    let mounted = true;
    const fetchData = () => {
      getJobs(1)
        .then((response) => {
          const jobsArray = [];
          if (response.data) {
            const data = response.data.data;
            data.forEach(job => {
              jobsArray.push(job);
            });
          }
          if (mounted) {
            setJobUrl(jobsArray[0].url);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchData();
    return () => mounted = false;
  }, []);

  useEffect(() => {
    setNextScreen(`/${getNextScreen(constants.EDU_INTEREST_SCREEN)}`);
    setSkip(nextScreen);
    // updateProfileStep(constants.EDU_INTEREST_SCREEN).then(()=> {
    //   console.log('Userprofile Updated Successfully for screen : ', constants.EDU_INTEREST_SCREEN);
    // });
  }, [nextScreen]);

  const onSubmit = (isSkip) => {
    const educationInterest = isSkip ? "maybe later" : form.current.radio.value;
    if (!educationInterest) {
      setError('Please, select one option.');
      return;
    }
    const data = {
      email: localStorage.getItem(constants.USER_EMAIL),
      step: constants.EDU_INTEREST_SCREEN,
      sign_up_source: localStorage.getItem(constants.SIGN_UP_SOURCE) || constants.HOT_COCOA,
      vendor_trustedform_token: form.current.xxTrustedFormToken.value,
      education: {
        education_interest: educationInterest
      }
    }
    // Commented Below Line Because of we have removed auto pages. and check same here and set next screen page here.
    // localStorage.setItem(constants.EDU_INTEREST, educationInterest);
    let nextStep = nextScreen;
    if (educationInterest === 'definitely not') {
      if (hotCocoaConfig) {
        const jsonHotCocoaConfig = JSON.parse(hotCocoaConfig)
        if (jsonHotCocoaConfig?.hococoaConfig?.eduexplore?.definitelynot) {
          nextStep = jsonHotCocoaConfig?.hococoaConfig?.eduexplore?.definitelynot;
        } else {
          nextStep = `/${constants.HOT_JOB_SCREEN}`
        }
      } else {
        nextStep = `/${constants.HOT_JOB_SCREEN}`
      }
    }
    updateProfile(data);
    history.push(nextStep);
  };

  const onChangeRadio = (event) => {
    changeBackground(event);
    if (form.current.radio.value === "definitely not") {
      onSubmit(false);
    } else {
      setHideClass('');
    }
  }

  return (
    <CardContainer title={constants.COMPLETE_PROFILE} progress={60}>
      <Form ref={form}>
        <Card.Title className="App-subtitle text-center">{constants.STEPS_TITLE}</Card.Title>
        <Card.Title className="App-question mt-4 mb-3">Are you interested in exploring options for furthering your education?</Card.Title>
        <Form.Check id="option1" name="radio" type="radio" value="yes" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option1" id="label_option1" name="options" className="Select-option">Yes</label>

        <Form.Check id="option2" name="radio" type="radio" value="maybe" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option2" id="label_option2" name="options" className="Select-option">Maybe</label>

        <Form.Check id="option3" name="radio" type="radio" value="maybe later" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option3" id="label_option3" name="options" className="Select-option">Maybe later</label>

        <Form.Check id="option4" name="radio" type="radio" value="definitely not" className="checkbox-hidden" onChange={onChangeRadio} />
        <label htmlFor="option4" id="label_option4" name="options" className="Select-option">Definitely not</label>

        <div className={`policy ${hideClass}`}>
          <label className="mt-2 mb-2">
            By clicking “Agree and Continue”, I agree to be contacted by phone or receive daily SMS text messages (msg and data rates may apply) by Next Step Education Center, MyDegree LLC, DegreeSearch, Degree Transfers, and Education Directory at the phone (including my wireless) number I provided regarding educational opportunities. I acknowledge that these calls or SMS messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required as a condition of purchasing any goods or services. To receive this information without providing consent, <a href={jobUrl} target='_blank' onClick={() => onSubmit(true)} rel="noopener noreferrer">Skip here</a>.
          </label>
        </div>
        <Form.Text className="text-center text-danger">{error}</Form.Text>
        <Button className={`Form-continue mt-4 ${hideClass}`} type="button" onClick={() => onSubmit(false)}>Agree and Continue</Button>
        <Policy />
      </Form>
      <SkipToJob/>
    </CardContainer>
  );
};

export default EducationExplore;