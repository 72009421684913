// eslint-disable-next-line
import React  from 'react';
import PolicyLinks from "./PolicyLinks";
import DoNotSellModal from "./DoNotSellModal";

const Policy = () => {
  return (
    <div className="policy-footer">
      <label className="mt-2 mb-2">
        Jobble.com is a job search engine. We are not an employer nor are we affiliated with any of the employers on
        this site. <PolicyLinks/> <DoNotSellModal/>
      </label>
    </div>
  );
};

export default Policy;